<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0 ma-0">
      <FormSection
        :label="'Customer Examples'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <v-alert type="warning" class="my-2" outlined>
        These documents may be used as an examples and may be shared with a
        customer. Please make sure that all document presented here contains
        watermarks and doesn't contains any project-related info.
      </v-alert>
      <VueFileAgent
        deletable
        :linkable="true"
        :crossOrigin="'use-credentials'"
        :editable="false"
        v-model="customerDocuments"
        :uploadUrl="getUploadURL('CUSTOMER')"
        :uploadHeaders="uploadHeaders"
        @upload="() => $emit('onAfterUpload')"
        @delete="(...props) => $emit('onFileDelete', 'CUSTOMER', ...props)"
      ></VueFileAgent>
      <FormSection
        :label="' Internal Examples'"
        class="mt-4"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>

      <v-alert class="my-2" dense outlined type="error">
        <strong>ALL</strong> documents uploaded are ADAAS IP and are protected
        by IP rights.
      </v-alert>
      <VueFileAgent
        deletable
        :linkable="true"
        :crossOrigin="'anonymous'"
        :editable="false"
        v-model="privateDocuments"
        :uploadUrl="getUploadURL('PRIVATE')"
        :uploadHeaders="uploadHeaders"
        @upload="() => $emit('onAfterUpload')"
        @delete="(...props) => $emit('onFileDelete', 'PRIVATE', ...props)"
      ></VueFileAgent>

      <FormSection
        :label="'Public Images'"
        class="mt-4"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <v-alert class="my-2" dense outlined type="error">
        This images are public and will be used as an examples on the Service
        preview. Please make sure tha these documents
        <strong>ARE NOT</strong> under ADAAS IP.
      </v-alert>
      <VueFileAgent
        deletable
        :linkable="true"
        accept="image/png, image/gif, image/jpeg, image/webp"
        v-model="publicDocuments"
        :editable="false"
        :uploadUrl="getUploadURL('PUBLIC')"
        :uploadHeaders="uploadHeaders"
        @upload="() => $emit('onAfterUpload')"
        @delete="(...props) => $emit('onFileDelete', 'PUBLIC', ...props)"
      ></VueFileAgent>
    </v-col>
  </v-row>
</template>
        
        
<script>
import FormSection from "../../../../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {},
    uploadURL: {
      type: String,
      default: "",
    },
    uploadHeaders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { privateDocuments, customerDocuments, publicDocuments } =
      this.prepareFiles(this.value);

    return {
      // Form
      pValid: this.valid,

      privateDocuments,
      customerDocuments,
      publicDocuments,
    };
  },
  components: {
    FormSection,
  },
  methods: {
    getUploadURL(type) {
      return `${this.uploadURL}/${type}/upload`;
    },
    prepareFiles(value) {
      let privateDocuments = value.privateMaterials || [];
      let customerDocuments = value.customerMaterials || [];
      let publicDocuments = value.publicMaterials || [];

      return { privateDocuments, customerDocuments, publicDocuments };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { privateDocuments, customerDocuments, publicDocuments } =
          this.prepareFiles(newVal);

        this.privateDocuments = privateDocuments;
        this.customerDocuments = customerDocuments;
        this.publicDocuments = publicDocuments;

      },
      deep: true,
    },

    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
<style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>